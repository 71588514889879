
import Avatar from "../assets/imgs/anonymous_user.png";
import React from "react";

const Player = (props: any) => {
    const { avatar, country } = props;
   
    return(
    <li className={`d-flex align-center player-item-leaderBoard ${!props.position ||props.position > props.limit ? 'hidden' : ''} ${props.enter ? 'item-enter' : ''} ${props.enterDone ? 'item-enter-active' : ''} ${props.exit ? 'item-exit-active' : ''} `} style={props.style}>
        <div className="player-position-leaderBoard d-flex align-center">
            <div style={{
                color: props.position > 0 && props.position <= 3 ? "#FFD700" : "white"
            }}>{props.position}</div>
            {/* {props.statusPosition === -1 ?
                <span><img src={TrendDown} alt="" /></span>
                : props.statusPosition === 1 ?
                    <span><img src={TrendUp} alt="" /></span> : undefined} */}
        </div>
        <div className="d-flex player-info-leaderBoard align-center" style={{
            backgroundColor: props.position > 0 && props.position <= 3 ? "#FFD700" : "#00FF85",display:props.show&&'none'
        }}>
             <img src={avatar ? `data:image/png;base64, ${avatar}` : Avatar} alt="" />
            { country && !!country.trim()  && (<div className=' player-flag-leaderBoard d-flex'>
                <img src={`../assets/imgs/countryflags/${country && country.toLowerCase()}.imageset/${country&&country.toLowerCase()}.png`} alt='flag'/>
            </div>)}

            <p className="player-name-leaderBoard">{props.name}</p>
            {props.cut&&(<div className='player-cut-leaderBoard'>{`${(props.score-props.cut)>0?'+':''}${(props.score-props.cut)}`}</div>)}
        </div>
        <div className="player-score-leaderBoard" style={{display:props.show &&'none'}}>
            {props.score}
        </div>
    </li>
)};

export default Player;