import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import MatchDetails from './MatchDetails';

const useStyles = makeStyles({
    root: {
      maxWidth: 671,
      backgroundColor:'#04162C',
      borderRadius:12,
      marginBottom:'auto'
    
    },
    title:{
        color:'#FFE888',
        fontSize:'1.875em',
        fontFamily:'SF Pro Display Bold',
        textAlign:'left',
        marginBottom:'.8em'
      
      },
    subTitle:{
        color:'#DEDEDE',
        fontFamily:'SF Pro Text Regular', 
        textAlign:'left',
        fontSize:24
    },
    content: {
        padding:'2.5em 4.25em'
    }
  });

const ModalMatch=(props:any)=>{

    const classes = useStyles();
 
    return(
      <>
       {props.show && 
       <div className='container-modal-match'>
         <div className='cover-card'>
           <Card className={classes.root}>
            <CardMedia
              component="img"
              alt="Match"
              height="343"
              image={`${props.image}`}
              title="Match Img"
            />
            <CardContent className={classes.content}>
              <Typography className={classes.title}>
                 {props.title}
              </Typography>
              <Typography className={classes.subTitle}>
                {props.description}
              </Typography>
            </CardContent>
            <div style={{width:'55%',marginLeft:'2.1em',color:'#FFFFFF',marginBottom:'2.5em'}}>
              <MatchDetails games={props.games} players={props.players} time={props.time}/>
            </div>
            </Card>
         </div>
       </div>   
        }
      </>
    )
}
export default ModalMatch;