import { HttpService } from "..";
import { Http } from "./http";
import SERVICE_ROUTE from "./serviceRoutes";


class HttpBase {
    http: any;

    constructor() {
        this.http = new Http('/');
    }

    getBowlingCenters = () => {
        return this.http.getRequest(`/${SERVICE_ROUTE.bowlingcenter}`)
    }

    getBowlingCenter = (id: string) => {
        return this.http.getRequest(`/${SERVICE_ROUTE.bowlingcenter}/${id}`)
    }

    getSlams = ({gender = '', page = 0}) => {
        const pageQuery = `page=${page}`;
        const genderQuery = gender ? `&gender=${gender}` : '';
        const query = `/?${pageQuery}${genderQuery}`
        return this.http.getRequest(`/${SERVICE_ROUTE.slams}${query}`);
    }

    getMatches = () => {
        return this.http.getRequest(`/${SERVICE_ROUTE.matches}`)
    }

    getMatchesById = (id: string) => {
        return this.http.getRequest(`/${SERVICE_ROUTE.matches}/${SERVICE_ROUTE.bowlingcenter}/${id}`)
    }

    getMatch = (id: string) => {
        return this.http.getRequest(`/${SERVICE_ROUTE.matches}/${id}`)
    }

    getPlayersPerPage = (id: any, page: any, bowlingCentersList:any, uuidBC:any, status:any) => {
        const url= status === 'centers' ? `/${SERVICE_ROUTE.matches}/${id}/leaderboard/bowlingcenter/${uuidBC}?page=${page}`
                            : `/${SERVICE_ROUTE.matches}/${id}/leaderboard?page=${page}`
        return  this.http.getRequest(url)
        .then((res:any)=>{
            let result: any = [];
            if(res.data && res.data.length){
                localStorage.setItem(url, JSON.stringify([...res.data ]));
                result = res.data
            }
            else {
                const info = localStorage.getItem(url);
                result = (info && JSON.parse(info))||[];
            }
            const games = (result && result.length && result.map(({games}: any) => games)
            .reduce((acc: any, curr: any)=>[...acc,...curr]))||[];

            if (games && games.length){
                return HttpService.getScore({games:games})
                    .then(({ data }: any) => {
                        return result.length && result.map((player: any) => {
                            player.lastScores = player.games.map((gameId:any) => {
                                return data.games.find(({id}:any)=> id === gameId)?.scores.pop();
                            });
                            player.endTimeGame=player.games.map((gameId:any) => {
                                return data.games.find(({id}:any)=> id === gameId).endTime;
                            }).pop();
                            player.infoBC = bowlingCentersList.find(({uuid}:any) => uuid === data.games?.find(({id}:any)=> id === player.games[0])?.bowlingCenterUuid)
                            return player; 
                        });
                    })
            }
            return result;

            })
            .catch(() => {
                console.log('no content');
            });
    }

    getMatchesByBC = (uuidBC:any) => {
        return  this.http.getRequest(`/${SERVICE_ROUTE.matches}/${SERVICE_ROUTE.bowlingcenter}/${uuidBC}`)
        .then(({data}:any)=>{
            const filtered: any = {};
            data && Object.keys(data).forEach(key => {
                filtered[key] = (data[key] || [])?.filter((match: any) => (match.restrictedTo?.bowlingCenters?.includes(uuidBC) || !match.restrictedTo?.bowlingCenters))
            });
            return filtered
        })
        .catch(() => {
            console.log('no content');
        });
    }

    getScore = (games: any) => {
        return this.http.postRequest(`/scorecards/details`, games)
    }

    getUserById = (userId: string) => {
        return this.http.getRequest(`/${SERVICE_ROUTE.users}/${userId}`)
    }

    getImage = (imageId: number) => {
        return this.http.getRequest(`/${SERVICE_ROUTE.images}/${imageId}`)
    }

    getAvatar = (userId: number) => {
        return this.http.getRequest(`/${SERVICE_ROUTE.users}/${userId}/avatar/thumbnail`)
    }
}

export default (new HttpBase())
