import React from 'react';
//import {useDispatch} from "react-redux";
import ContentApp from "./ContentApp";
//import {setLanguage} from 'redux-i18n';
//import FooterApp from "./FooterApp";
import "./styles.css"
//import HeaderApp from './HeaderApp';

const LayoutApp = (props: any) => {
    const {children} = props;
    //const {pathname} = props.location;
    //const isLeaderBoardRoute = pathname.includes('/leaderboard');

    //const dispatch = useDispatch();
    //const changeLang = useCallback((locale) => dispatch(setLanguage(locale)), [dispatch]);

    return (
        <div className="container">
             {/* <Helmet>
                <title>Lanetalk Leaderboard top lists</title>
                <meta name="description" content="Lanetalk Leaderboard top lists"/>
            </Helmet> */}
          { /* <HeaderApp></HeaderApp>*/}
            <ContentApp className={`content`}>
                {children}
            </ContentApp>
           {/*<FooterApp className="footer d-flex align-center justify-between"
                       changeLanguage={(newLocale: string) => changeLang(newLocale)}
    showFlags={!isLeaderBoardRoute}/>*/}

        </div>
    )
};

export default LayoutApp
