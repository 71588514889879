import React from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from "react-redux";
import lanetalkLogo from '../assets/imgs/lanetalk_logo.svg';
import usaFlag from '../assets/imgs/usa.png';
import swedenFlag from '../assets/imgs/sweden.png';

const FooterApp = (props: any, context: any) => {
    const {t} = context;
    const {showFlags, changeLanguage} = props;

    const lang = useSelector((state: any) => state.i18nState.lang);

    return (
        <footer className={props.className}>
            <div className="author-container">
                <div className="powered">{t('powered_by')}</div>
                <div className="logo">
                    <img src={lanetalkLogo} alt="Lanetalk"/>
                </div>
            </div>
            {showFlags &&
            <div className="lang-container">
              <button className={`btn-lang btn-transparent ${lang === 'en' ? 'active' : undefined}`}
                      onClick={() => changeLanguage('en')}><img src={usaFlag} alt="usa-flag"/></button>
              <button className={`btn-lang btn-transparent ${lang === 'sw' ? 'active' : undefined}`}
                      onClick={() => changeLanguage('sw')}><img src={swedenFlag} alt="sweden-flag"/>
              </button>
            </div>
            }
        </footer>
    )
};
FooterApp.contextTypes = {
    t: PropTypes.func
};

export default connect()(FooterApp);
