import React,{useEffect,useState} from "react";
import Avatar from "../assets/imgs/anonymous_user.png";
import iconNew from '../assets/imgs/new-score.svg';
import iconNewCell from '../assets/imgs/new-score-cell.svg';
import moment from "moment";

const Player = (props: any) => {
    const { infoBC, lastScores, endTime, avatar, countryCode, gamesAmount } = props;
    const { location:city, country, companyName } = infoBC ||{};
    const [newScore, setNewScore] = useState(false);

    useEffect(() => {
     const time:any=moment().diff(moment.unix(endTime),"hours");
     setNewScore(time<20)
    }, [endTime]);

    return(
    <li className='d-flex player-item' style={props.style} onClick={()=>props.handleOnClickPlayer(props.id)}>  
      {newScore && ( <img src={ window.innerWidth < 631?iconNewCell:iconNew} className={`scoreNew ${props.expanded && window.innerWidth < 631 && 'scoreNewExpanded'}`} alt=''/>)}
      <div className='gradient' style={window.innerWidth > 631? props.gradientStyles:{background:'transparent'}}> </div>
      <div className={`player-position d-flex ${props.expanded && window.innerWidth < 631 && 'paddingExpanded'} `} style={{color: props.status === 'disqualified'?'#FF004E':''}}>
          {props.status === 'disqualified'? "-" : props.position}
      </div>
      <div className={`player-flag ${props.expanded && window.innerWidth < 631 && 'paddingExpanded'}`}>
          {countryCode && !!countryCode.trim() &&
            <img src={`../assets/imgs/countryflags/${countryCode && countryCode.toLowerCase()}.imageset/${countryCode && countryCode.toLowerCase()}.png`} alt=''/>}
      </div>
      <div className='info-games d-flex' style={{width: !props.cutScore? '85%':'76%'}}>
        <div className='d-flex info-avatar'>
            <img className={`${!props.expanded && window.innerWidth < 631 && 'display-none'}`} src={avatar ? `data:image/png;base64, ${avatar}` : Avatar}  alt="" />
            <div className="player-info flex-column" >
              <div className={`player-name ${props.status === 'disqualified' && "playerDisqualified"}`}>
                <span>{props.name}</span>
                <span>{` ${props.lastName}`||''}</span>
              </div>
              <div className='player-bowling'>{companyName}</div>
              <div className='player-location'>
                {city && (<span>{`${city}${country?', ':''}`}</span>)}
                <span>{country || ''}</span>
              </div>
            </div>
        </div>
       <div className={`player-container-games d-flex ${!props.expanded && window.innerWidth < 631 && props.status!=='disqualified'&&'display-none'}`}>
          {props.status !== 'disqualified'? lastScores && lastScores.map((g:any,i:any)=>(<div key={i} className='player-games-wrapper'><div className='player-games'>{g}</div></div>))
                : <span className={`cheater ${window.innerWidth < 631 && props.status==='disqualified' && 'cheaterVerified'}`} >Score verified and disqualified</span>}
          {props.status === "failed" &&
            new Array(gamesAmount).fill(null).map((item, index) => (
              <div className="player-games-wrapper" key={index}>
                <div className="player-games-failed">
                  {index === 0 ? "DNF" : ""}
                </div>
              </div>
            ))}
        </div>     
      </div>
      <div className={"containerScores"}>
        <div  className={"player-scores"}>
        {props.cutScore &&(<div className={`player-score ${props.expanded && window.innerWidth < 631 && 'paddingExpanded'}`} style={{fontFamily:'SF Pro Text Regular'}}>
            <div>{props.status!=='disqualified'?`${(props.score)-(props.cutScore) > 0 ?'+':''}${(props.score)-(props.cutScore)||0}`:''}</div>
        </div>)}
        <div className={`player-score ${props.expanded && window.innerWidth < 631 && 'paddingExpanded'}`}>
            <div className={`${props.status==='disqualified' && "playerDisqualified"}`}>{props.status!=='disqualified'? props.score :"SVD"}</div>
        </div>
        </div>
        {props.status==='verified' && (<div className={'scoreVerified'} style={props.styleVerified}>Score verified</div>)}
      </div>
     
    </li>
  )  
};

export default Player;