import {combineReducers} from 'redux';
import {i18nState} from "redux-i18n"
import {connectRouter} from 'connected-react-router';
import app from './app';
import slams from './slams';

export default (history: any) => combineReducers({
    router: connectRouter(history),
    i18nState,
    app,
    slams,
})