import React, { useState, useEffect, useCallback } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import iconBack from "../../assets/imgs/back-new.png";
import CardSlam from "../../componentsUI/CardSlam";
import PlayerSlam from "../../componentsUI/PlayerSlam";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions/app";
import useMedia from "../../hooks/use-media";
import InfiniteScroll from "react-infinite-scroll-component";
import SpinnerLoading from "../../componentsUI/spinner-loading/SpinnerLoading";
import ManFilterImage from "../../assets/imgs/Men.png";
import WomanFilterImage from "../../assets/imgs/Women.png";

const SlamMatches = (props: any, context: any) => {
  const slams: any[] = useSelector((state: any) => state.slams.SLAMS);
  const canLoadMore: boolean = useSelector(
    (state: any) => state.slams.CANLOADMORE
  );

  const dispatch = useDispatch();
  const loadSlams = useCallback(
    (data) => dispatch(actions.loadSlams(data)),
    [dispatch]
  );
  const clearSlamsState = useCallback(
    () => dispatch(actions.clearSlamsState()),
    [dispatch]
  );

  useEffect(() => {
    clearSlamsState();
    loadSlams({ gender: filter });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newSlams = slams.map((item) => {
      const pointsByMonth = [
        { month: "Jan", points: item?.january },
        { month: "Feb", points: item?.february },
        { month: "Mar", points: item?.march },
        { month: "Apr", points: item?.april },
        { month: "May", points: item?.may },
        { month: "Jun", points: item?.june },
        { month: "Jul", points: item?.july },
        { month: "Aug", points: item?.august },
        { month: "Sep", points: item?.september },
        { month: "Oct", points: item?.october },
        { month: "Nov", points: item?.november },
        { month: "Dec", points: item?.december },
      ];

      return { ...item, pointsByMonth };
    });
    setPlayersList(newSlams);
  }, [slams]);

  const [slamSelected, setSlamSelected] = useState({});
  const [filter, setFilter] = useState("");
  const [playersList, setPlayersList] = useState([{ id: 0 }]);
  const [expanded, setExpanded] = useState(null);

  const media = useMedia(
    ["(max-width: 930px)", "(min-width: 931px)"],
    ["mobile", "desktop"],
    "desktop"
  );

  const columnsHeader: any = {
    position: {
      mobile: "Pos",
      desktop: "POSITION",
    },
    name: {
      mobile: "Player Name",
      desktop: "PLAYER NAME",
    },
    total: {
      mobile: "Slam Points",
      desktop: "SLAM Points",
    },
  };

  const headerPlayer = {
    id: 0,
    position: columnsHeader.position[media],
    lastName: columnsHeader.name[media],
    firstName: "",
    country: "",
    total: columnsHeader.total[media],
    pointsByMonth: [
      { month: "Jan" },
      { month: "Feb" },
      { month: "Mar" },
      { month: "Apr" },
      { month: "May" },
      { month: "Jun" },
      { month: "Jul" },
      { month: "Aug" },
      { month: "Sep" },
      { month: "Oct" },
      { month: "Nov" },
      { month: "Dec" },
    ],
  };

  useEffect(() => {
    setSlamSelected({
      title: "SLAM TOUR",
      description: `SLAM is a free-to-enter monthly global match! Collect points in the SLAM TOUR every month to make it to the Super 16!`,
      coverImageUrl: "",
    });
  }, []);

  const handleOnClickPlayer = (index: any) => {
    setExpanded(expanded === index ? null : index);
  };

  const sendRequest = () => {
    if (slams.length > 0) {
      loadSlams({ gender: filter });
    }
  };

  const applyFilterHandler = (filter: string) => {
    setFilter(filter);
    clearSlamsState();
    loadSlams({ gender: filter });

    switch (filter) {
      case "male":
        setSlamSelected({
          ...slamSelected,
          coverImageUrl: ManFilterImage,
        });
        break;
      case "female":
        setSlamSelected({
          ...slamSelected,
          coverImageUrl: WomanFilterImage,
        });
        break;
      default:
        setSlamSelected({
          ...slamSelected,
          coverImageUrl: "",
        });
        break;
    }
  };

  return (
    <div>
      <div className={`header-slam`}>
        <img
          src={iconBack}
          alt="glass"
          className="back"
          onClick={() => props.history.push('/')}
        />
      </div>
      <div className="container-body">
        <CardSlam slamSelected={slamSelected} />
        <div className="container-btn-filters">
          <button
            className={`btn-filter-slam ${filter === "male" && "active"}`}
            onClick={() =>
              filter === "male"
                ? applyFilterHandler("")
                : applyFilterHandler("male")
            }
          >
            Men
          </button>
          <button
            className={`btn-filter-slam ${filter === "female" && "active"}`}
            onClick={() =>
              filter === "female"
                ? applyFilterHandler("")
                : applyFilterHandler("female")
            }
          >
            Woman
          </button>
        </div>
        <div className="container-players-slam">
          <InfiniteScroll
            dataLength={playersList.length}
            next={sendRequest}
            hasMore={canLoadMore}
            loader={
              <div style={{ display: "flex", justifyContent: "center" }}>
                <SpinnerLoading />
              </div>
            }
            style={{ overflow: "hidden" }}
          >
            <PlayerSlam player={headerPlayer} header />
            {playersList &&
              playersList.map((player, i) => (
                <PlayerSlam
                  player={player}
                  key={i}
                  expanded={expanded === i}
                  handleOnClickPlayer={() => handleOnClickPlayer(i)}
                />
              ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

SlamMatches.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SlamMatches;
