import React from 'react';
import './App.css';
import {Switch} from "react-router";
import {GuestRoute} from "./layout/routes";
import routes from "./routes";
import SelectBowlingcenterContainer from "./pages/select-bowlingcenter/SelectBowlingCenterContainer";
import LayoutApp from "./layout/LayoutApp";
import ReactGA from 'react-ga';
import {BrowserRouter} from 'react-router-dom';

ReactGA.initialize('UA-168670-3');
ReactGA.pageview(window.location.pathname + window.location.search);
const App: React.FC = (props: any) => {

    const { location, history } = props;

    return (
        <BrowserRouter>
            <LayoutApp location={location}>
                <Switch>
                    <GuestRoute location={location} history={history} exact path="/" Component={SelectBowlingcenterContainer} />
                    {routes.map((prop: any, key: any) =>
                        <prop.routetype
                            path={prop.path}
                            Component={(props: any) => <prop.routeComponent {...props} />}
                            key={key}
                            location={location}
                            history={history}
                        />
                    )}
                </Switch>
            </LayoutApp>
        </BrowserRouter>
        
    );
};

export default App;