import { put, call, delay } from 'redux-saga/effects';
import actions from '../actions/app';
import { HttpService } from "../../services";
import { LocalStorageService } from "../../services";
import moment from "moment";
import Store from "../store";

export function* setNotifierfetchData(action: any) {
    try {
        if (action.data.open) {
            yield delay(3000);
            yield put(actions.setNotifier({ open: false }));
        }
    } catch (error) {
    }
}

export function* bowlingcentersFetchData() {
    try {
        const data = yield call(HttpService.getBowlingCenters)
        yield put(actions.loadBowlingcentersSuccess(data));
    } catch (error) {
        yield put(actions.requestFails(error));
    }
}

export function* slamsFetchData(action: any) {
    try {
        yield put(actions.setLoadingslams(true));
        const page = Store.getState().slams.PAGE;       
        const data = yield call(HttpService.getSlams, {...action.payload, page: page});
        const lim = 50;        
        yield put(actions.setCanLoadMoreSlams(data.data.length === lim));        
        yield put(actions.setLoadingslams(false));
        yield put(actions.setPageSlams());
        yield put(actions.loadSlamsSuccess(data));
    } catch (error) {
        yield put(actions.setCanLoadMoreSlams(false));
        yield put(actions.setLoadingslams(false));
        yield put(actions.requestFails(error));
    }
}

export function* elementsHistoryFetchData() {
    try {
        const data = yield LocalStorageService.get('elementSelected');
        yield put(actions.loadElementsHistorySuccess(data));
    } catch (error) {
        yield put(actions.requestFails(error));
    }
}

export function* bowlingCentersHistoryFetchData() {
    try {
        const data = yield LocalStorageService.get('bowlingCenterSelected');
        yield put(actions.loadBowlingCentersHistorySuccess(data));
    } catch (error) {
        yield put(actions.requestFails(error));
    }
}

export function* selectBowlingcenter(action: any) {
    try {
        const elementsSelectedHistory: any[] = LocalStorageService.get('bowlingCenterSelected');
        let res: any[] = [];
        if (!!elementsSelectedHistory) {
            yield res = elementsSelectedHistory.slice(elementsSelectedHistory.length >= 3 ? -2 : -1 * elementsSelectedHistory.length)
        }
        const data = yield HttpService.getBowlingCenter(action.payload)
        if (res.filter(b => b.companyName === data.data.companyName).length === 0) {
            yield res.push(data.data)
            yield LocalStorageService.save('bowlingCenterSelected', res)
        }
        yield put(actions.selectBowlingcenterSuccess(data));
    } catch (error) {
        yield put(actions.requestFails(error));
    }
}

export function* matchesFetchData(action: any) {
    try {
        yield put(actions.setLoading(true));
        let data = yield HttpService.getMatches();
        data = (data.data.ongoing || []).filter((match: any) => {
            return match.privacy === "public";

        }).concat((data.data.finished || []).filter((match: any) => {
            match.finished = true;
            return match.privacy === "public";
        }
        ))
        .sort((a: any, b: any) => [-1, 1][+(a.expires > b.expires)]);
        
        yield put(actions.setLoading(false));
        yield put(actions.loadMatchesSuccess(data));
    } catch (error) {
        yield put(actions.requestFails(error));
        yield put(actions.setLoading(false));
    }
}

export function* matchFetchImage(action: any) {
    try {
        if (action.payload) {
            const data = yield HttpService.getImage(action.payload)
            const image = typeof data.data === 'string' ? data.data : data.data.data
            yield put(actions.loadImagesMatchesSuccess({ id: action.payload, image: image }));
        }
    } catch (error) {
        yield put(actions.requestFails(error));
    }
}

export function* selectedMatchFetchData(action: any) {
    try {
        let data: any = yield call(HttpService.getMatch, action.payload)
        yield put(actions.selectMatchSuccess(data.data));
    } catch (error) {
        yield put(actions.requestFails(error));
    }
}

export function* comparePositionPlayer(action: any) {
    try {
        let positionsPlayerHistory: any[] = LocalStorageService.get('positionsPlayerHistory') || [];
        const player: any = positionsPlayerHistory?.filter((ph: any) => ph.id === action.payload.id)[0]
        const res = {
            id: action.payload.id,
            value: (!player) || (
                player &&
                player.value !== action.payload.value) ? action.payload.value : undefined,
            timestamp: moment.now()
        }
        if (!player && !!res.value) {
            positionsPlayerHistory.push(res)
            yield LocalStorageService.save('positionsPlayerHistory', positionsPlayerHistory)
        }
        else if (player && !!res.value) {
            positionsPlayerHistory = positionsPlayerHistory.map((ph) => ph.id === res.id ? res : ph)
            yield LocalStorageService.save('positionsPlayerHistory', positionsPlayerHistory)
        }
        if (!!res.value)
            yield put(actions.refreshPositionsPlayersSuccess(positionsPlayerHistory));
    } catch (error) {
        yield put(actions.requestFails(error));
    }
}

export function* refreshPositionsPlayers(action: any) {
    try {
        let positionsPlayerHistory: any[] = LocalStorageService.get('positionsPlayerHistory')?.
            filter((ph: any) =>
                moment(new Date()).diff(moment(ph.timestamp), 'days') <= 2);
        yield LocalStorageService.save('positionsPlayerHistory', positionsPlayerHistory)
        yield put(actions.refreshPositionsPlayersSuccess(positionsPlayerHistory));
    } catch (error) {
        yield put(actions.requestFails(error));
    }
}

export function* playerFetchAvatar(action: any) {
    try {
        if (action.payload) {
            const data = yield HttpService.getUserById(action.payload);
            const avatar = typeof data.data.thumbnail === 'string' ? data.data.thumbnail : data.data.data;
            yield put(actions.loadAvatarSuccess({ id: action.payload, avatar: avatar}));
        }
    } catch (error) {
        yield put(actions.requestFails(error));
    }
} 