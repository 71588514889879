import React from "react";
import I18n, {ITranslations} from "redux-i18n"
//translations
import en from "./en.json";
import sw from "./sw.json";

const translations:ITranslations = {
    "en": en,
    "sw": sw,
};

const I18nProvider = (props: any) => {
    const {children} = props;

    return (
        <I18n translations={translations} initialLang="en">
            {children}
        </I18n>
    )
};

export default I18nProvider;