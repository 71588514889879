import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position:'absolute',
    bottom:0
  },
  colorPrimary:{
      backgroundColor:'#000000'
  },
  barColorPrimary:{
    border: '2px solid #FFFFFF'
}
});

export default function LinearLoading(props:any) {
  const classes = useStyles();

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(0);
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
        //   if (oldProgress === 100) {
        //     return 0;
        //   }
          const diff = 5;
          return Math.min(oldProgress + diff, 100);
        });
      }, props.timeProgress.time);
  
      return () => {
        clearInterval(timer);
      };
    }, [props.timeProgress]);

  return (
    <div className={classes.root}>
      <LinearProgress variant="determinate" value={progress}  classes={{
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary
        }}/>
    </div>
  );
}