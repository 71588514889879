import React from 'react';
import gamesIcon from '../assets/imgs/games.svg';
import playersIcon from '../assets/imgs/players.svg';
import timeIcon from '../assets/imgs/time.svg';

const MatchDetails=(props:any)=>{
    const {time,games,players}=props

    return (
    <div style={{display:'flex',justifyContent:'space-evenly',width:'100%'}}>
        <div className='d-flex align-center'>
            <img src={gamesIcon} alt='games' style={{marginRight:'4px'}}/>
            {games}
            </div>
        <div className='d-flex align-center'>
            <img src={playersIcon} alt='players' style={{marginRight:'4px'}}/>
            {players}
        </div>
        <div className='d-flex align-center'>
            <img src={timeIcon} alt='time' style={{marginRight:'4px'}}/>
            {time}
        </div>
    </div>
    
    )
}
export default MatchDetails;