import React, { useCallback, useEffect, useRef, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions/app";
import "./styles.css"
//import BackButton from "../../componentsUI/BackButton";
import 'react-multi-carousel/lib/styles.css';
import Table from '../../componentsUI/custom-table/table'
import { HttpService } from "../../services";
import { LocalStorageService } from "../../services";
import Lottie from 'react-lottie';
import animationData from '../../assets/imgs/animation.json';
import iconSearch from '../../assets/imgs/glass-new.svg';
import iconClear from '../../assets/imgs/close.svg';
import icon from '../../assets/imgs/Union.svg'

const SelectMatchesContainer = (props: any, context: any) => {
  const { t } = context;
  const [criteria, setCriteria] = useState('');
  const [showClearBtn, setShowClearBtn] = useState(false);
  const [valuesMatches, setValueMatches] = useState([]);
  const [finished, setFinished] = useState(false);
  const [matchesFiltered, setMatchesFiltered] = useState(Array.of([]));
  const [isLoading, setLoading] = useState(false);
  const columns = [
    {
      Header: '',
      accessor: 'coverImageUrl', // accessor is the "key" in the data
      width:'10%'
    },
    {
      Header: t('title'),
      accessor: 'title',
    
    },
    {
      Header: t('type')/*'Title',*/,
      accessor: 'type',
     width:'20%'
    },
    {
      Header: t('end_date'),
      accessor: 'expires',
     width:'18%'
    },
];

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};
/*SelectMatchesContainer */
  const idBowlingCenter = props.location.pathname.split('/')[3];
  const bowlingCenterSelected: any = useSelector((state: any) => state.app.BOWLINGCENTER_SELECTED);
  const elementsHistory: any[] = useSelector((state: any) => state.app.ELEMENTS_HISTORY);
  const lastElements:any[]=elementsHistory&& (elementsHistory.slice(elementsHistory.length>=3?-3:-1*elementsHistory.length))
  const imageList: any = useSelector((state: any) => state.app.IMAGES_MATCHES);

  const firstLoad = useRef(false);
  const dirty = useRef(false);

  const dispatch = useDispatch();
  const loadImage = useCallback((data) => dispatch(actions.loadImagesMatches(data)), [dispatch]);
  const loadElementsHistory = useCallback(() => dispatch(actions.loadElementsHistory()), [dispatch]);
  const selectBowlingCenter = useCallback((data) => dispatch(actions.selectBowlingcenter(data)), [dispatch]);

  
  useEffect(() => {
    setValueMatches([]);
    setLoading(true)
    HttpService.getMatchesByBC(idBowlingCenter)
      .then((data: any) => {
        const onGoing = data && data.ongoing && data.ongoing
        .sort((a: any, b: any) => [-1, 1][+(a.expires > b.expires)]);
        const finish= data && data.finished?.sort((a: any, b: any) => [-1, 1][+(a.expires > b.expires)])
        setValueMatches(finished? finish: onGoing);
        setLoading(false)
      })
  }, [idBowlingCenter,finished]);

  useEffect(() => {
    selectBowlingCenter(idBowlingCenter);
    loadElementsHistory();
  }, [selectBowlingCenter, idBowlingCenter,loadElementsHistory]);

  useEffect(() => {
    if (!firstLoad.current && !!valuesMatches && valuesMatches.length > 0) {
      valuesMatches.map((m: any) =>
        imageList?.filter((a: any) => a.id === m.coverImageId)[0]?.image || loadImage(m.coverImageId)
      )
      firstLoad.current = true;
    }
  }, [valuesMatches, imageList, loadImage]);

  useEffect(() => {
    if (dirty.current) {
        const matchesSearch=valuesMatches?.filter((m:any) => m.title.toLowerCase().includes(criteria.toLowerCase()));
        setMatchesFiltered(matchesSearch)
    }
}, [criteria,valuesMatches]);

useEffect(() => {
 window.scrollTo(0,0);
}, [])

  const handleInputChange = (evt: any) => {
    if (!dirty.current)
        dirty.current = true
    // set input value in state
    setCriteria(evt.target.value);
    if (evt.target.value.length > 0) {
        // show/hide clear btn
        setShowClearBtn(true);
        // set loading state
    } else {
        setShowClearBtn(false);
        dirty.current = false
    }
  };

  const handleClearInput = () => {
    setCriteria('');
    setShowClearBtn(false);
    dirty.current=false;
   };

   const handleSelectMatch = (match: any) => {
    const res: any[] = elementsHistory || [];
    if (!lastElements || lastElements?.filter(b => b === criteria).length === 0) {
      res.push(criteria)
      LocalStorageService.save('elementSelected', res);
   } 
    props.history.push(`/leaderboard/${match.id}?BC=${idBowlingCenter}`)
 };

   const selectItemHistory = (search:any) => {
   handleInputChange({target:{value:search}})
  }

   const handleSetFinished=()=>{
     if(dirty.current&&finished){
      dirty.current=false;
      setCriteria('')
     }
     setFinished(!finished);
    }

  return (
    <Fragment>
      <div className='header header-matches'>
        <span>
          <a className='lanetalkHeader' href="https://www.lanetalk.com/centers/scoring-systems.html" >Activate Lanetalk</a>
        </span>
      </div>
      <div className="text-center sel-match-wrapper select-matches">
        {/* <span className="back">
          <img src={iconBack} alt="glass" onClick={()=> props.history.goBack()}/>
        </span> */}
        <div className='container-subheader'>
        <div className='container-title'>
          <div className='subtitle'>
            <span>{bowlingCenterSelected&&bowlingCenterSelected.data.companyName}</span>
          </div>
          <h1>{t('select-match-title')}</h1>
        </div>
         <div className='select-games'>
                   <button className={`btn-primary ${finished && 'btn-transparent'}`} onClick={handleSetFinished}>Ongoing</button>
                   <button className={`btn-primary ${!finished && 'btn-transparent'}`} onClick={handleSetFinished}>Finished</button>
          </div>
          
          {finished && (
          <div className='container-matches-list'>
          <div className="matches-lists">
                <div className='search-input d-flex align-center'>
                    <span className="search-ico">
                        <img src={iconSearch} alt="Magnify glass" />
                    </span>
                    <input type="text" value={criteria} placeholder={t('select_matches_searchPlaceholder')}
                        onChange={handleInputChange} />
                    {
                        showClearBtn && (
                            <button className='search-clear' onClick={handleClearInput}>
                                <img src={iconClear} alt="close icon" />
                            </button>
                        )
                    }
                </div> 
          </div>
          </div>
          )}
          {finished && (<div className='matches-history d-flex justify-center'> 
                { lastElements?.map((e: any, i: number) => (
                                 <li key={`${e} ${i}`}
                                 className={`color-transparent ${!dirty.current&&'prevSelected'}`}
                                 onClick={() => selectItemHistory(e)}>
                                 {e}
                             </li>
                            ))
                }
            </div>)}
            </div>
        {valuesMatches?.length > 0 ?  <Table matches={dirty.current?matchesFiltered:valuesMatches} imageList={imageList} handleSelectMatches={handleSelectMatch} myColumns={columns} t={t} onGoing={finished} criteria={criteria} classTable={'containerTable'}/> 
                              : isLoading ? 
                              <div className='container-loading' style={{marginTop: !finished?'4em':''}}>
                              <Lottie options={defaultOptions} height={200} width={200}/> 
                              </div>
                              :<h2>No available matches</h2>
                             }
        
         <div className='iconBottom'>
              <img src={icon} alt="" />
         </div>
         <span className={`footerActiveLanetalk ${dirty.current&& !(matchesFiltered.length>0) && 'up'}`}>
                <a  className='activeLanetalk' href="https://www.lanetalk.com/centers/scoring-systems.html" >Activate Lanetalk</a>
        </span>
      </div>
     
    </Fragment>
  )
};

SelectMatchesContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SelectMatchesContainer;
