import {GuestRoute} from "./layout/routes";
import PagesRoutes from "./pages/PagesRoutes.routes";

const dashboardRoutes = [
    {
        path: "/",
        name: "Select Bowling Center",
        routeComponent: PagesRoutes,
        routetype: GuestRoute
    },
];

export default dashboardRoutes