import React, { useCallback, useEffect, useRef, useState } from "react";
import ASCIIFolder from "fold-to-ascii";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions/app";
import "./styles.scss";
import Table from "../../componentsUI/custom-table/table";
import Lottie from "react-lottie";
import animationData from "../../assets/imgs/animation.json";
import banner from "../../assets/imgs/banner.png";

const SelectBowlingCenterContainer = (props: any, context: any) => {
  const { t } = context;
  const bowlingCenters: any[] = useSelector(
    (state: any) => state.app.BOWLINGCENTERS
  );
  const loadingMatches: any[] = useSelector((state: any) => state.app.LOADING);
  const bowlingCentersHistory: any[] = useSelector(
    (state: any) => state.app.BOWLINGCENTERS_HISTORY
  );
  const columns = [
    {
      Header: "",
      accessor: "coverImageUrl", // accessor is the "key" in the data
      width: "10%",
    },
    {
      Header: t("title"),
      accessor: "title",
    },
    {
      Header: t("type") /*'Title',*/,
      accessor: "type",
      width: "20%",
    },
    {
      Header: t("end_date"),
      accessor: "expires",
      width: "18%",
    },
  ];

  // STATE
  const [showClearBtn, setShowClearBtn] = useState(false);
  const [criteria, setCriteria] = useState("");
  const [bowlingcentersFiltered, setBowlingcentersFiltered] = useState(
    Array.of([])
  );
  const [isLoading, setLoading] = useState(false);
  const [valuesMatches, setValueMatches] = useState([]);
  const [isMobileResolution, setIsMobileResolution] = useState(false);

  const mounted = useRef(false);
  const dirty = useRef(false);
  const dispatch = useDispatch();
  const loadBowlingCenter = useCallback(
    () => dispatch(actions.loadBowlingcenters()),
    [dispatch]
  );
  const loadBowlingCenterHistory = useCallback(
    () => dispatch(actions.loadBowlingCentersHistory()),
    [dispatch]
  );

  const matches: any = useSelector((state: any) => state.app.MATCHES);
  const imageList: any = useSelector((state: any) => state.app.IMAGES_MATCHES);
  const firstLoad = useRef(false);

  const loadMatches = useCallback(
    () => dispatch(actions.loadMatches()),
    [dispatch]
  );
  const loadImage = useCallback(
    (data) => dispatch(actions.loadImagesMatches(data)),
    [dispatch]
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (!mounted.current) {
      loadBowlingCenter();
      loadBowlingCenterHistory();
      loadMatches();
    }
    mounted.current = true;
  }, [loadBowlingCenter, loadBowlingCenterHistory, loadMatches]);

  useEffect(() => {
    if (dirty.current) {
      setBowlingcentersFiltered(
        bowlingCenters.filter((b) =>
          ASCIIFolder.foldMaintaining(b.companyName)
            .toLowerCase()
            .includes(ASCIIFolder.foldMaintaining(criteria).toLowerCase())
        )
      );
      setLoading(false);
    }
  }, [criteria, bowlingCenters]);

  useEffect(() => {
    if (!firstLoad.current && !!matches && matches.length > 0) {
      matches.map(
        (m: any) =>
          imageList?.filter((a: any) => a.id === m.id)[0]?.image ||
          loadImage(m.coverImageId)
      );
      firstLoad.current = true;
      setValueMatches(matches);
    }
  }, [matches, imageList, loadImage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsMobileResolution(window.innerWidth <= 630);
    window.onresize = () => {
      setIsMobileResolution(window.innerWidth <= 630);
    };
  }, []);

  const handleSelectMatch = (match: any) => {
    props.history.push(`/leaderboard/${match.id}`);
  };

  const handleSelectBowlingCenter = (bowlingcenter: any) => {
    props.history.push(`matches/bowlingcenters/${bowlingcenter.uuid}`);
  };

  const handleInputChange = (evt: any) => {
    if (!dirty.current) dirty.current = true;
    // set input value in state
    setCriteria(evt.target.value);
    if (evt.target.value.length > 0) {
      // show/hide clear btn
      setShowClearBtn(true);
      // set loading state
      setLoading(true);
    } else {
      setShowClearBtn(false);
      setLoading(false);
      dirty.current = false;
    }
  };

  const handleClearInput = () => {
    // clean input value
    setCriteria("");
    // hide clear button
    setShowClearBtn(false);
    // set loading state
    setLoading(false);
    dirty.current = false;
  };

  return (
    <>
      <div className="SelectBowlingCenterContainer">
        <div className="header header-bc">
          <span>
            <a
              className="lanetalkHeader"
              href="https://www.lanetalk.com/centers/scoring-systems.html"
            >
              Activate Lanetalk
            </a>
          </span>
        </div>
        <a
          href="https://lanetalkab.app.link/subscriptionsList"
          target="_blank"
          rel="noopener noreferrer"
          className="BannerLinkWrapper"
        >
          <img src={banner} alt="Banner" className="Banner" />
        </a>
        <div className="text-center sel-bowl-wrapper">
          <div className="container-subheader bowlingCenter">
            <h1>{t("select_bowling_center_title")}</h1>
            <div className="bowling-lists">
              <div
                className={`search-input d-flex align-center ${
                  dirty.current && "no-border-radius"
                }`}
              >
                <span className="search-ico">
                  <img src="./assets/imgs/glass-new.svg" alt="Magnify glass" />
                </span>
                <input
                  type="text"
                  value={criteria}
                  placeholder={t("select_bowling_center_searchPlaceholder")}
                  onChange={handleInputChange}
                />
                {showClearBtn && (
                  <button className="search-clear" onClick={handleClearInput}>
                    <img src="./assets/imgs/close.svg" alt="close icon" />
                  </button>
                )}
              </div>
              {dirty.current && (
                <div className="container-scroll">
                  <ul className="bowling-centers d-flex">
                    {isLoading ? (
                      <div className="loading-list">
                        <div />
                        <div />
                        <div />
                      </div>
                    ) : bowlingcentersFiltered.length > 0 ? (
                      bowlingcentersFiltered &&
                      bowlingcentersFiltered.map(
                        (bowlingCenter: any, i: number) => (
                          <li
                            key={`${bowlingCenter.id} ${i}`}
                            className="d-flex align-center justify-between"
                            onClick={() =>
                              handleSelectBowlingCenter(bowlingCenter)
                            }
                          >
                            {bowlingCenter.companyName}
                            <span
                              style={{ fontSize: "10px", textAlign: "end" }}
                            >{`${bowlingCenter.location}, ${bowlingCenter.country}`}</span>
                          </li>
                        )
                      )
                    ) : (
                      <div className="notavailableBC">
                        <span style={{ color: "#D60D51" }}>{`${t(
                          "no_bc"
                        )} "${criteria}"`}</span>
                        <span
                          style={{
                            fontFamily: "SF Pro Text Regular",
                            marginTop: "12px",
                            fontSize: "12px",
                          }}
                        >
                          {t("keyword")}
                        </span>
                      </div>
                    )}
                  </ul>
                </div>
              )}
            </div>
            {bowlingCentersHistory && (
              <div className="bc-history d-flex justify-center">
                {bowlingCentersHistory.map((bowlingCenter: any, i: number) => (
                  <li
                    key={`${bowlingCenter.id} ${i}`}
                    className={`color-transparent ${
                      !dirty.current && "prevSelected"
                    }`}
                    onClick={() => handleSelectBowlingCenter(bowlingCenter)}
                  >
                    {bowlingCenter.companyName}
                  </li>
                ))}
              </div>
            )}
          </div>
          {valuesMatches.length > 0 ? (
            <>
              <Table
                matches={valuesMatches.filter(
                  (m: any) => m.specialEvent || m.nearBy
                )}
                imageList={imageList}
                handleSelectMatches={handleSelectMatch}
                myColumns={columns}
                t={t}
                classTable={"table1"}
              />
              <Table
                matches={valuesMatches.filter(
                  (m: any) => !m.specialEvent && !m.nearBy && !m.finished
                )}
                imageList={imageList}
                handleSelectMatches={handleSelectMatch}
                myColumns={columns}
                t={t}
                classTable={"table2"}
                isRowspan={isMobileResolution}
              />
              <Table
                matches={valuesMatches.filter((m: any) => m.finished)}
                imageList={imageList}
                handleSelectMatches={handleSelectMatch}
                myColumns={columns}
                t={t}
                onGoing={true}
                classTable={"table3"}
                isRowspan={isMobileResolution}
              />
            </>
          ) : loadingMatches ? (
            <Lottie options={defaultOptions} height={200} width={200} />
          ) : (
            <h2 style={{ color: "#FFFFFF", fontFamily: "SF Pro Text Regular" }}>
              No available matches
            </h2>
          )}
          <div className="buttons-row">
            <button
              className="btn-primary-slam"
              onClick={() => props.history.push("/slam-matches")}
            >
              Slam Tour
            </button>
          </div>
          <span className={`footerActiveLanetalk`}>
            <a
              className="activeLanetalk"
              href="https://www.lanetalk.com/centers/scoring-systems.html"
            >
              Activate Lanetalk
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

SelectBowlingCenterContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SelectBowlingCenterContainer;
