class LocalStorage {
    save = (key: string, value: any) => {
        localStorage.setItem(key, JSON.stringify(value));
    };
    get = (key: string) => {
        if (this.checkExist(key))
            return JSON.parse(localStorage.getItem(key) || '');
        else
            return null;
    };
    checkExist = (key: string) => {
        return localStorage.getItem(key) !== null ? true : false;
    };
    clear = (key: string) => {
        localStorage.removeItem(key);
    };
    clearAll = () => {
        localStorage.clear();
    };
}

export default (new LocalStorage());