import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DefaultMatch from "../assets/imgs/default-slam.jpeg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    boxShadow: "none",
    background: "transparent",
    textAlign: "left",
    alignItems: "center",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    minWidth: 371,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  content: {
    flex: "0 1 auto",
    paddingLeft: 53,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 28,
    },
  },
  cover: {
    height: 194,
  },
  title: {
    color: "#00FF85",
    fontSize: 50,
    fontFamily: "SF Pro Display Bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 23,
      letterSpacing: 0.33,
    },
  },
  subTitle: {
    color: "#FFFFFF",
    fontFamily: "SF Pro Text Regular",
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      letterSpacing: -0.23,
    },
  },
}));

const SlamMatch = (props: any, context: any) => {
  const classes = useStyles();
  const { title, description, coverImageUrl } = props.slamSelected || {};

  return (
    <div className="container-card-slam">
      <Card className={`${classes.root} root`}>
        <div className={classes.details}>
          <CardMedia
            className={classes.cover}
            image={`${coverImageUrl || DefaultMatch}`}
            title="Live from space album cover"
          />
        </div>
        <CardContent className={classes.content}>
          <Typography className={`${classes.title} title`}>{title}</Typography>
          <Typography className={classes.subTitle}>{description}</Typography>
        </CardContent>
      </Card>
    </div>
  );
};

SlamMatch.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SlamMatch;
