import React, {useEffect,useState } from 'react';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import PlayerCut from '../../componentsUI/CutPlayer';
import LinearLoading from '../../componentsUI/LinearLoading';

import MatchDetails from '../../componentsUI/MatchDetails';
import ModalMatch from '../../componentsUI/ModalMatch';
//import actions from "../../store/actions/app";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "./styles.css";
import AdImg1en from "../../assets/imgs/ads/ENGLISH/ad-1.png";
import AdImg2en from "../../assets/imgs/ads/ENGLISH/ad-2.png";
import AdImg3en from "../../assets/imgs/ads/ENGLISH/ad-3.png";
import AdImg1sv from "../../assets/imgs/ads/SWEDISH/ad-1.png";
import AdImg2sv from "../../assets/imgs/ads/SWEDISH/ad-2.png";
import AdImg3sv from "../../assets/imgs/ads/SWEDISH/ad-3.png";
import Player from "../../componentsUI/Player";


const carouselSettings = {
    responsive: {
        desktop: {
            breakpoint: { max: 4200, min: 1024 },
            items: 1,
            paritialVisibilityGutter: 100
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    },
    containerClass: 'carousel-root',
    infinite: true,
    autoPlay: true,
    autoPlaySpeed: 10000,
    arrows: false,
    slidesToSlide: 1,
    draggable: false,

};

const LeaderBoard = (props: any, context: any) => {
    const{ players, cut, cutPosition, title, games, amountPlayers, time, image, description, refreshMatch}=props;
    const { t } = context;
    const images: any = {
        en: [AdImg1en, AdImg2en, AdImg3en],
        sv: [AdImg1sv, AdImg2sv, AdImg3sv]
    }
    const itemsPerPage = 10;
    const timeInterval = 10000;

    const avatarsList: any = useSelector((state: any) => state.app.AVATARS) || [];
   
    const [currentPage, setCurrentPage] = useState(1);
    const [playersPerList, setPlayersPerList] = useState(players.length > 0 ? players.slice(0, itemsPerPage) : []);
    const [show, setShowModal] = useState(false);
    const [enter, setEnter] = useState(false);
    const [enterDone, setEnterDone] = useState(false);
    const [exit, setExit] = useState(false);
    const [exitDone, setExitDone] = useState(false);
    const [init, setInit] = useState(true);
    const [timeProgress, setTimeProgress] = useState({time: 500});

    useEffect(() => {
        let timeInit: any = null;
        setShowModal(true);
       timeInit = setTimeout(() => {
            setShowModal(false);
            setInit(false);
            setTimeProgress({time: 500});
        }, 10500);
        
    return () => {
        clearTimeout(timeInit)
    }
    },[]);

   // useEffect to run animation in/out
    useEffect(() => {
        const handleModalTime = async () => {
            let timeModal: any = null;
            setInit(true);
            await new Promise(resolve => {
                setShowModal(true);
                refreshMatch();
                setCurrentPage(1);
                setPlayersPerList(players.slice(0, itemsPerPage));
                timeModal = setTimeout(() => {
                    setInit(false);
                    resolve(0);
                }, 10500);
            })
            setShowModal(false);
            setTimeProgress({time: 500});
    
            return () => {
                clearTimeout(timeModal)
            }
        };

        let timeout: any;
        if (players && !init) {
                timeout = setTimeout(() => {
                    setTimeProgress({time: 500});
                    // clean the list to execute animation out
                    setEnter(false);
                    setEnterDone(false);
                    setExit(true);
                    setExitDone(false);
                    // after 500ms sec add new items to list
                    setTimeout(() => {
                        setEnter(true);
                        setEnterDone(false);
                        setExit(false);
                        setExitDone(true);
                        // update state with new page players
                        //
                        if (currentPage < (players.length / itemsPerPage)) {
                            setPlayersPerList(players.slice(currentPage * itemsPerPage, currentPage * itemsPerPage + itemsPerPage));
                            setCurrentPage(currentPage + 1);
                        } else {                        
                         handleModalTime(); 
                        }

                        setTimeout(() => {
                            setEnter(false);
                            setEnterDone(true);
                            setExit(false);
                            setExitDone(true);
                        }, 500);
                    }, 500);

                }, timeInterval);
            return () => {
                clearTimeout(timeout)
            }
        } else {
            setPlayersPerList(players);

        }
    }, [currentPage, players,init,refreshMatch]);

    const transitionStyles = (pos: any) => {
        if (enter) {
            return { transform: 'translateY(620px)' }
        }
        if (enterDone) {
            return {
                transform: 'translateY(0px)',
                transitionDelay: `${pos * 0.05}s`
            }
        }
        if (exit) {
            return { transform: `translateY(-${pos * 62}px)` }
        }
        if (exitDone) {
            return { transform: `translateY(620px)` }
        }

        return {}
    };

    return (
        <>
        <div className='content-leaderBoard'>
            <div className="ads-side">
                <Carousel {...carouselSettings}>
                    <img src={images[t('lang')][0]} alt="Lanetalk announcements" />
                    <img src={images[t('lang')][1]} alt="Lanetalk announcements" />
                    <img src={images[t('lang')][2]} alt="Lanetalk announcements" />
                </Carousel>
            </div>

            <div className="players-side text-center">
                <h1 style={{opacity: show?'0':'1'}}>{title}</h1>
                <div className='container-match-details' style={{opacity: show?'0':'1'}}>
                   <MatchDetails games={games} players={amountPlayers} time={time}/>
                </div>
                <ul className="players-list-leaderBoard" style={{ overflowY:'hidden' }}>
                    {new Array(playersPerList.length).fill('').map((x, i) =>{
                       return (
                        <>
                            <Player position={playersPerList[i]?.position}
                                    name={`${playersPerList[i]?.firstName} ${playersPerList[i]?.lastName}`}
                                    score={playersPerList[i]?.totalScore}
                                    key={`${playersPerList[i]?.id} ${i}`}
                                    id={playersPerList[i]?.id}
                                    cut={cut}
                                    avatar={avatarsList[playersPerList[i]?.id].avatar}
                                    country={playersPerList[i]?.country || playersPerList[i]?.infoBC?.countryCode}
                                    style={transitionStyles(i + 1)}
                                    enter={enter}
                                    enterDone={enterDone}
                                    exit={exit}
                                    exitDone={exitDone}
                                    show={show}
                                    limit={currentPage*10}
                            />
                            {cutPosition === playersPerList[i]?.position && (
                                <PlayerCut scoreCut={cut} position={playersPerList[i]?.position}  style={transitionStyles(i + 1)} viewTv={true} showModal={show}/>
                            )}
                        </>
                       )   
                       }    
                    )}
                </ul>
                <ModalMatch image={image} description={description} title={title} games={games} players={amountPlayers} time={time} show={show}/>
                <LinearLoading timeProgress = {timeProgress} />
            </div>
        </div>
        </>
    )
};

LeaderBoard.contextTypes = {
    t: PropTypes.func.isRequired,
};

export default LeaderBoard;