import {createActions} from "reduxsauce";

export const {Types, Creators} = createActions({
        loadBowlingcenters: [],
        loadBowlingcentersSuccess: ['payload'],
        loadSlams: ['payload'],
        loadSlamsSuccess: ['payload'],        
        loadElementsHistory: [],
        loadElementsHistorySuccess: ['payload'],
        loadBowlingCentersHistory: [],
        loadBowlingCentersHistorySuccess: ['payload'],
        selectBowlingcenter: ['payload'],
        selectBowlingcenterSuccess: ['payload'],
        loadMatches: ['payload'],
        loadMatchesSuccess: ['payload'],
        loadImagesMatches: ['payload'],
        loadImagesMatchesSuccess: ['payload'],
        selectMatch: ['payload'],
        selectMatchSuccess: ['payload'],
        comparePositionPlayer: ['payload'],
        refreshPositionsPlayers: ['payload'],
        refreshPositionsPlayersSuccess: ['payload'],
        loadAvatar: ['payload'],
        loadAvatarSuccess: ['payload'],
        setLoading: ['isLoading'],
        clearState: [],
        requestFails: ['error'],
        setNotifier: ['data'],
        setLoadingslams: ['isLoading'],
        clearSlamsState: [],
        setCanLoadMoreSlams: ['canLoadMore'],
        setPageSlams: []
    },
    {
        prefix: '@@ROOT/'
    });
export const types = Types;
export default Creators;