import React,{ useCallback, useEffect, useRef,useState} from 'react';
import { HttpService } from "../../services";
import ASCIIFolder from 'fold-to-ascii';
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions/app";
import PropTypes from 'prop-types';
import Player from '../../componentsUI/PlayerNew';
import PlayerCut from '../../componentsUI/CutPlayer';
import CardMatch from '../../componentsUI/CardMatch';
import InfiniteScroll from "react-infinite-scroll-component";
import LeaderBoard from './LeaderBoard';
import FooterApp from '../../layout/FooterApp';
import moment from 'moment';
import "./styles.css";
import Lottie from 'react-lottie';
import animationData from '../../assets/imgs/animation.json';
import iconSearch from '../../assets/imgs/glass-new.svg';
import iconBack from '../../assets/imgs/back-new.png';
import iconClear from '../../assets/imgs/close.svg';
import DefaultMatch from '../../assets/imgs/match_default.jpg';
import gold from '../../assets/imgs/gold.png';
import silver from '../../assets/imgs/silver.png';
import bronze from '../../assets/imgs/bronze.png';


const LeaderBoardContainer = (props: any, context: any) => {
    const { t } = context;

    const matchSelected: any = useSelector((state: any) => state.app.MATCH_SELECTED.players);
    const finished=moment.unix(matchSelected && matchSelected.expires)>moment()?false:true;
    const avatarsList: any = useSelector((state: any) => state.app.AVATARS) || [];
    const imageList: any = useSelector((state: any) => state.app.IMAGES_MATCHES);
    const bowlingCentersList: any = useSelector((state: any) => state.app.BOWLINGCENTERS);
   
    const mounted = useRef(false);
    const dirty=useRef(false);
    const cutRef = useRef<null | HTMLDivElement>(null);
    const lastRef=useRef<null | HTMLDivElement>(null);
    const dispatch = useDispatch();

    const selectMatch = useCallback((data) => dispatch(actions.selectMatch(data)), [dispatch]);
    const loadAvatar = useCallback((playerId) => dispatch(actions.loadAvatar(playerId)), [dispatch]);
    const refreshPositions = useCallback(() => dispatch(actions.refreshPositionsPlayers()), [dispatch]);
    const loadImage = useCallback((data) => dispatch(actions.loadImagesMatches(data)), [dispatch]);
    const loadBowlingCenter = useCallback(() => dispatch(actions.loadBowlingcenters()), [dispatch]);
 
    const [isLoading, setLoading] = useState(false);
    const [playersPerList, setPlayersPerList] = useState([{id:'',position:0,firstName:'',totalScore:0,lastName:'',games:[],status:"",lastScores:[],country: null,infoBC:{companyName:'',country:'',location:'',countryCode:''},endTimeGame:''}]);
    const [playersLeaderBoard, setPlayersLeaderBoard] = useState([{id:'',position:0,firstName:'',totalScore:0,lastName:'',games:[]}]);
    const [match, setMatch] = useState<null | {coverImageId: null, coverImageUrl: null,expires:any,starts:any,cutScore:any,cutPosition:any,title:'', description:'', games:any, players:any, id:any}>(null);
    const [criteria, setCriteria] = useState('');
    const [showClearBtn, setShowClearBtn] = useState(false);
    const [expanded, setExpanded] = useState(null);
    const [image, setImage] = useState('');
    const [idMatch, setIdMatch] = useState(null);
    const [time, setTime] = useState(0);
    const [page, setPage] = useState(0);
   
    const [restrictedToBc, setRestrictedToBc] = useState(true);
    const [status, setStatus] = useState('');
    const [endPlayers, setEndPlayers] = useState(false);

    const uuidBC=(props.location && props.location.search.split('=')[1]) || null;
    const bcName=bowlingCentersList.find((b:any)=>b.uuid===uuidBC)?.companyName;
    const limit=50;
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

    const refreshMatch = () => {
        selectMatch(idMatch);
    }

    useEffect(() => {
      !bowlingCentersList.length &&  loadBowlingCenter();
    }, [bowlingCentersList,loadBowlingCenter])

    useEffect(() => {
        setIdMatch(props.location.pathname.split('/')[2]);
        setRestrictedToBc(props.location.search !== '')
    }, [props.location]);

    useEffect(() => {
       setStatus(restrictedToBc?'centers':'all')
    }, [restrictedToBc])

    useEffect(() => {
        mounted.current = false;
     }, [status])

    useEffect(() => { 
        const mapData =  (data : any) => {
            if(data) {
                data.map((p:any)=> {
                      p.id && !avatarsList[p.id] && loadAvatar(p.id)
                        return ''   
                    });
                setPlayersPerList(data);  
                setPlayersLeaderBoard(data?.length>30?data.slice(0,30):data)
                setPage(1);
                setEndPlayers(data.length < limit)
            }
            setLoading(false);
            mounted.current = true;
        };   
        if (!mounted.current && idMatch && bowlingCentersList.length) {
            setLoading(true);
            setPlayersPerList([]);
            selectMatch(idMatch);
            refreshPositions();
            if (status==='all') { 
                HttpService.getPlayersPerPage(idMatch,1,bowlingCentersList,uuidBC,status).then(mapData)
            } else if (uuidBC) {
                HttpService.getPlayersPerPage(idMatch,1,bowlingCentersList,uuidBC,status).then(mapData)
            }
        }
    }, [idMatch, bowlingCentersList,uuidBC,status,avatarsList,loadAvatar,selectMatch,refreshPositions]);

   
    const fetchMoreData = () => {
        HttpService.getPlayersPerPage(idMatch,page+1,bowlingCentersList,uuidBC,status)
            .then(( data : any) => {
                if(data) {
                    data.map((p:any)=> {
                        p.id && !avatarsList[p.id] && loadAvatar(p.id)
                        return ''   
                    });
                    setPlayersPerList(playersPerList.concat(data));  
                    setPage(page+1);
                    setEndPlayers(data.length < limit || (match?.id === 3960 && playersPerList?.length === 4950)
                                  ||(match?.id === 4037 && playersPerList?.length === 950))
                }
            })
      };

    useEffect(() => {
        if(idMatch=== matchSelected?.id.toString()){  
            if(!matchSelected?.coverImageUrl)
                imageList?.filter((a: any) => a.id === matchSelected?.coverImageId)[0]?.image || loadImage(matchSelected?.coverImageId)
            setMatch(matchSelected)
        }
        else {
            setMatch(null)
            setPlayersPerList([])
        }
    }, [matchSelected, idMatch,imageList,loadImage])
    
    useEffect(() => {
        const img: any = match?.coverImageId && imageList.find(((image: any) => image.id === match.coverImageId))?.image;
        setImage((match?.coverImageUrl) || (img && `data:image/png;base64,${img}`) || DefaultMatch);
        const t= moment.unix(match?.expires).diff(moment.unix(match?.starts),"days");   
        setTime(t)

      }, [match, imageList]);

    const handleInputChange = (evt: any) => { 
        if (!dirty.current)
            dirty.current = true
        // set input value in state
        setCriteria(evt.target.value);
        if (evt.target.value.length > 0) {
            // show/hide clear btn
            setShowClearBtn(true);
            // set loading state
        } else {
            setShowClearBtn(false);
            dirty.current = false
        }
      };

    const handleClearInput = () => {
        setCriteria('');
        setShowClearBtn(false);
        dirty.current = false;
    };

    const handleOnClickPlayer=(id:any)=>{
        setExpanded(expanded === id? null: id);
    }

    const gradientStyles=(search: any, name:any,lastName:any,position:any) => {
        const fillName = ASCIIFolder.foldMaintaining(`${name} ${lastName}`).toLowerCase();
        const fillNameInv=ASCIIFolder.foldMaintaining(`${lastName} ${name}`).toLowerCase();
        const criteri = ASCIIFolder.foldMaintaining(search).toLowerCase();

        if (dirty.current && (fillName.indexOf(criteri) >-1 || fillNameInv.indexOf(criteri) >- 1)) {
            return { background: '#00FF85' }
        } else if (finished) {
            switch(position) {
                case 1:
                    return { backgroundImage: `url(${gold})`}
                case 2:
                    return { backgroundImage: `url(${silver})`}
                case 3:
                    return { backgroundImage: `url(${bronze})`}
            }
    }
        return{}
    }

    const backgroundStyles = (search: any, name:any,lastName:any,position:any) => {
        const fillName = ASCIIFolder.foldMaintaining(`${name} ${lastName}`).toLowerCase();
        const fillNameInv=ASCIIFolder.foldMaintaining(`${lastName} ${name}`).toLowerCase();
        const criteri = ASCIIFolder.foldMaintaining(search).toLowerCase();

        if (dirty.current && (fillName.indexOf(criteri) >-1 || fillNameInv.indexOf(criteri) >- 1)) {
            return { background: '#00FF85' }
        } else if (finished) {
            switch (position) {
                case 1:
                    return { background: '#EBB948'}
                case 2:
                    return { background: '#BDBDBD'}
                case 3:
                    return { background: '#BA6D29'}  
            }
        }
        return{}
    };

    const backgroundVerified = (search: any, name:any,lastName:any,position:any) => {
        const fillName = ASCIIFolder.foldMaintaining(`${name} ${lastName}`).toLowerCase();
        const fillNameInv=ASCIIFolder.foldMaintaining(`${lastName} ${name}`).toLowerCase();
        const criteri = ASCIIFolder.foldMaintaining(search).toLowerCase();

        if (dirty.current && (fillName.indexOf(criteri) >-1 || fillNameInv.indexOf(criteri) >- 1)) {
            return { background: '#05D16F' }
        }
        return{background: '#00FF85'}
    };

    return (
        <>
            <div className={`header-leaderBoard justify-between ${status==='leaderBoardTv' && 'flex-end'}`}>
                <img src={iconBack} alt="glass" className='back' onClick={()=> props.history.goBack()}/>
                {status!=='leaderBoardTv' && (<div  className='container-input-header d-flex align-center justify-center'>
                    <div className='input-header d-flex align-center'>
                        <span className="search-ico-header">
                            <img src={iconSearch} alt="glass" />
                        </span>
                            <input type="text" value={criteria}  placeholder={t('select_leaderBoard_searchPlaceholder')}
                             onChange={handleInputChange} />
                        {
                            showClearBtn && (
                            <button className='search-clear' onClick={handleClearInput}>
                                <img src={iconClear} alt="close icon" />
                            </button>
                        )
                    }
                    </div>
                    <div className='navigation'>
                        <span onClick ={() => window.scrollTo(0,0)}>First</span>
                        <span onClick ={() => window.scrollTo(0, (lastRef?.current?.offsetTop || 0)-100)}>Last</span>
                       { match?.cutScore &&(<span onClick ={() => window.scrollTo(0, (cutRef?.current?.offsetTop || 0)-100)}>The Cut</span>)}
                    </div>
                </div>)}
                <div className='container-btn'>
                    { restrictedToBc && ( <button className={`btn-primary ${status!=='centers'?'transparent':''}`} 
                                      style={{marginRight:'20px'}} 
                                      onClick={()=>setStatus('centers')}>Center Participants</button>
                            )
                    } 
                    <button className={`btn-primary ${status!=='all'?'transparent':''} ${!restrictedToBc && "btnLeaderBoard"}`} 
                            style={{marginRight:'20px'}} 
                            onClick={()=>setStatus('all')}>All</button>
                    <button className={`btn-primary ${status!=='leaderBoardTv'?'transparent':''} btnLeaderBoard`} 
                            onClick={()=>setStatus('leaderBoardTv')}>Leaderboard</button>           
                </div>
            </div>
            <div className={`sel-players-wrapper ${status==='leaderBoardTv' && 'leaderBoard'}`}>
                {status!=='leaderBoardTv' &&(<div className='container-details text-center'>
                    <CardMatch matchSelected={match} time={time} image={image} bcName={bcName}/>
                    <div className='header-cards'>
                            <div className='player-position'> Position</div>
                            <div style={{width:'3.7%'}}></div>
                            <div className='info-games d-flex'  style={{width: !(match?.cutScore)? '85%':'76%'}}>
                                <div className='d-flex info-avatar'>
                                    <div className="player-info flex-column" >Player Name</div>
                                </div>
                                <div className='player-container-games d-flex'> 
                                { new Array(match?.games).fill(0).map((v, index) => (<div key={index} className='player-games-wrapper'><div className='player-games'>{`G${index+1}`}</div></div>)) }
                                </div>
                            </div>
                            <div className="containerScores">
                                <div className={"player-scores"}>
                                    {match?.cutScore && (<div className='player-score'>
                                        <div style={{textAlign:"center"}}>THE CUT</div>
                                </div>)}
                                <div className='player-score'>
                                  <div>Score</div>
                                </div> 
                                </div>
                            </div>
                    </div>
                    {playersPerList.length > 0 ? (
                        <InfiniteScroll
                            dataLength={playersPerList.length}
                            next={fetchMoreData}
                            hasMore={!endPlayers}
                            loader={<h4 style={{color:'#FFFFFF'}}>Loading...</h4>}
                            style={{overflow:'visible'}}
                            className='players-list'
                             >
                                {playersPerList && playersPerList.map((player, i) => {
                                    return (
                                            <div key={i}>
                                                <Player position={player.position}
                                                        name={player.firstName}
                                                        lastName={player.lastName}
                                                        score={player.totalScore}
                                                        id={player.id}
                                                        avatar={avatarsList[player.id]?.avatar}
                                                        style={backgroundStyles(criteria, player.firstName, player.lastName, null)}
                                                        games={player.games}
                                                        lastScores={player.lastScores}
                                                        gradientStyles={gradientStyles(criteria, player.firstName,player.lastName,null)}
                                                        handleOnClickPlayer={handleOnClickPlayer}
                                                        expanded={expanded===player.id}
                                                        cutScore={match?.cutScore}
                                                        status={player.status}
                                                        countryCode={player.country || player.infoBC.countryCode}
                                                        infoBC={player.infoBC}
                                                        endTime={player.endTimeGame}
                                                        styleVerified={backgroundVerified(criteria, player.firstName,player.lastName,player.position)}
                                                        gamesAmount={match?.games}
                                                />
                                                {(match?.cutScore && match?.cutPosition) === (playersPerList && playersPerList[i]?.position) && (
                                                    <>
                                                        <div ref={cutRef} style={{position:'absolute'}}></div>
                                                        <PlayerCut  scoreCut={match?.cutScore}/>
                                                    </>
                                                )}
                                            </div>
                                    )
                                })
                            }
                        </InfiniteScroll>)
                        :(isLoading ? <Lottie options={defaultOptions} height={200} width={200}/>
                            :<h3>No player has completed the match yet</h3>)
                    }    
                    <div ref={lastRef}></div>
                </div>)}
                { status === 'leaderBoardTv' && (
                <LeaderBoard players={playersLeaderBoard} 
                             cut={match?.cutScore} 
                             cutPosition={match?.cutPosition}
                             title={match?.title}
                             description={match?.description}
                             games={`${match?.games} games`}
                             amountPlayers={match?.players.length}
                             time={`${time}d`}
                             image={image}
                             refreshMatch ={refreshMatch}
                             />)}
            </div>
            { status === 'leaderBoardTv' && (<FooterApp className="footer d-flex align-center justify-between"/>)}
        </>
    )
};

LeaderBoardContainer.contextTypes = {
    t: PropTypes.func.isRequired,
};

export default LeaderBoardContainer;