const SERVICE_ROUTE = {
    root: '',
    bowlingcenter: 'bowlingcenters',
    matches: 'matches',
    players: 'players',
    images: 'images',
    users: 'users',
    slams: 'slams'
}

export default SERVICE_ROUTE;