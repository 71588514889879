import React, { useState, useEffect } from "react";
import CustomTable from "./CustomTable";
import "./styles.css";
import DefaultMatch from "../../assets/imgs/match_default.jpg";
import moment from "moment";

const Table = (props: any) => {
  const {
    matches,
    handleSelectMatches,
    imageList,
    myColumns,
    t,
    criteria,
    onGoing,
    isRowspan = false,
  } = props;
  const [values, setValues] = useState([]);
  const [transformData, setTransformData] = useState([]);

  const addComposeFields = (data: any) => {
    const transformedData = data.map((row: any) => {
      const coverImageUrl = (
        <div id="image" className="containerImage">
          <img src={`${row.coverImageUrl}`} alt="" className="image" />
        </div>
      );
      const type = (
        <div className={`${!row.type ? "fontBold" : undefined}`}>
          {row.type}
        </div>
      );
      const title = isRowspan ? (
        <div style={{ fontFamily: "SF Pro Text Bold" }}>
          <p>{row.title}</p>
          <p>{row.specialEvent ? t("special_event") : t("global_match")}</p>
          <p>{row.expires}</p>
        </div>
      ) : (
        <div style={{ fontFamily: "SF Pro Text Bold" }}>{row.title}</div>
      );
      return { ...row, type, title, coverImageUrl };
    });
    return transformedData;
  };

  useEffect(() => {
    const val = matches?.map((match: any) => {
      const image =
        match.coverImageId &&
        imageList.find((image: any) => image.id === match.coverImageId)?.image;
      return {
        ...match,
        coverImageUrl:
          match.coverImageUrl ||
          (image && `data:image/png;base64,${image}`) ||
          DefaultMatch,
        expires: moment.unix(match.expires).format("YYYY-MM-DD"),
        /*type: match.specialEvent?window.innerWidth>631?t('special_event'):t('se'):window.innerWidth>631?t('global_match'):t('gm')*/
        type: match.specialEvent ? t("special_event") : t("global_match"),
      };
    });
    setValues(val || []);
  }, [matches, imageList, t, isRowspan]);

  useEffect(() => {
    setTransformData(addComposeFields(values));
    // eslint-disable-next-line
  }, [values]);

  const data = React.useMemo(() => transformData, [transformData]);

  const columns = React.useMemo(() => myColumns, [myColumns]);

  return (
    <div
      className={`${props.classTable} ${values.length > 0 ? "" : "no_padding"}`}
    >
      {values.length > 0 ? (
        <CustomTable
          columns={columns}
          data={data}
          handleSelectMatches={handleSelectMatches}
          onGoing={onGoing}
        />
      ) : (
        props.classTable === "containerTable" && (
          <div className="notavailableMatches">
            <span>{`${t("no_match")} "${criteria}"`}</span>
            <span
              style={{ fontFamily: "SF Pro Text Regular", marginTop: "12px" }}
            >
              {t("keyword")}
            </span>
          </div>
        )
      )}
      {/* <span className={`${styles.footerActiveLanetalk} ${values.length>0?'':styles.bottomActivate}`}>
                <a  className={styles.activeLanetalk} href="https://www.lanetalk.com/centers/scoring-systems.html" >Activate Lanetalk</a>
        </span> */}
    </div>
  );
};
export default Table;
