import { createReducer } from "reduxsauce";
import { Types } from "../actions/app";
import update from "immutability-helper";

export const getInitial = () => ({
  SLAMS: [],
  PAGE: 0,
  CANLOADMORE: true,
  LOADING: false,
});

export const initial = getInitial();

const handlers = {
  [Types.SET_LOADINGSLAMS]: (state: any, action: any) => {
    return update(state, {
      LOADING: { $set: action.isLoading },
    });
  },
  [Types.SET_PAGE_SLAMS]: (state: any, action: any) => {
    return update(state, {
      PAGE: { $set: state.PAGE + 1 },
    });
  },
  [Types.CLEAR_SLAMS_STATE]: (state: any, action: any) => {
    return getInitial();
  },
  [Types.LOAD_SLAMS_SUCCESS]: (state: any, action: any) => {
    return update(state, {
      SLAMS: {
        $set: [...state.SLAMS, ...action.payload.data],
      },
    });
  },
  [Types.SET_CAN_LOAD_MORE_SLAMS]: (state: any, action: any) => {
    return update(state, {
      CANLOADMORE: { $set: action.canLoadMore },
    });
  },
};
const slams = createReducer(getInitial(), handlers);

export default slams;
