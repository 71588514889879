import React from 'react';
import { Switch } from 'react-router-dom';
import { GuestRoute } from "../layout/routes";
import SelectBowlingcenterContainer from "./select-bowlingcenter/SelectBowlingCenterContainer";
import SelectMatchesContainer from "./select-matches/SelectMatchesContainer";
import LeaderBoardContainer from './leaderboard/LeaderBoardAll';
import SlamMatches from './slam-matches/slamMatches';

const PagesRoutes = (props: any) => {

    const { location, history, match } = props;

    return (
        <>
            <Switch>
                <GuestRoute location={location} history={history} exact path={`${match.path}/`} Component={SelectBowlingcenterContainer} />
                <GuestRoute location={location} history={history} exact path={`${match.path}matches/bowlingcenters/:idBowlingCenter`} Component={SelectMatchesContainer} />
                <GuestRoute location={location} history={history} exact path={`${match.path}leaderboard/:idMatch`} Component={LeaderBoardContainer} />
                <GuestRoute location={location} history={history} exact path={`${match.path}slam-matches`} Component={SlamMatches} />
            </Switch>            
        </>
    )
}

export default PagesRoutes;
