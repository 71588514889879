import {Types} from '../actions/app';
import { takeEvery } from 'redux-saga/effects';
import * as appSagas from "./app";

export default function* rootSaga() {
    yield takeEvery(Types.SET_NOTIFIER, appSagas.setNotifierfetchData);
    yield takeEvery(Types.LOAD_BOWLINGCENTERS, appSagas.bowlingcentersFetchData);
    yield takeEvery(Types.LOAD_SLAMS, appSagas.slamsFetchData);
    yield takeEvery(Types.LOAD_ELEMENTS_HISTORY, appSagas.elementsHistoryFetchData);
    yield takeEvery(Types.LOAD_BOWLING_CENTERS_HISTORY, appSagas.bowlingCentersHistoryFetchData);
    yield takeEvery(Types.LOAD_MATCHES, appSagas.matchesFetchData);
    yield takeEvery(Types.LOAD_IMAGES_MATCHES, appSagas.matchFetchImage);
    yield takeEvery(Types.LOAD_AVATAR, appSagas.playerFetchAvatar);
    yield takeEvery(Types.SELECT_MATCH, appSagas.selectedMatchFetchData);
    yield takeEvery(Types.SELECT_BOWLINGCENTER, appSagas.selectBowlingcenter);
    yield takeEvery(Types.COMPARE_POSITION_PLAYER, appSagas.comparePositionPlayer);
    yield takeEvery(Types.REFRESH_POSITIONS_PLAYERS, appSagas.refreshPositionsPlayers);
}; 