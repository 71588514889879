import React from "react";
import cut from "../assets/imgs/cut.svg";

const PlayerCut = (props: any) => {
    return(
        <li className={`d-flex player-cut-item ${props.showModal? 'hidden' : ''} ${props.enter ? 'item-enter' : ''} ${props.enterDone ? 'item-enter-active' : ''} ${props.exit ? 'item-exit-active' : ''} `} style={props.style}>
        <span style={{marginLeft:'auto'}}>THE</span>
        <img src={cut} alt='' style={{margin:'0 10px'}}/>
        <span style={{marginRight:'auto'}}>CUT</span>
        <hr style={{border:'0',borderTop:'1px dotted red',width:'100%',position:'absolute'}}/>
    </li>
    )
}
export default PlayerCut;