import {createReducer} from 'reduxsauce';
import {Types} from '../actions/app';
import update from 'immutability-helper';
import moment from "moment";

export const getInitial = () => ({
    BOWLINGCENTERS: [],
    ELEMENTS_HISTORY: [],
    BOWLINGCENTERS_HISTORY: [],
    BOWLINGCENTER_SELECTED: undefined,
    MATCHES: [],
    POSITIONS_PLAYERS: [],
    AVATARS: {},
    USERS:{},
    IMAGES_MATCHES: [],
    MATCH_SELECTED: {},
    LOADING: false,
    NOTIFIER: {
        open: false,
        message: '',
        type: 'primary'
    }
})

export const initial = getInitial();

const handlers = {
    [Types.SET_LOADING]: (state: any, action: any) => {
        return update(state, {
            LOADING: {$set: action.isLoading},
        });
    },
    [Types.SET_NOTIFIER]: (state: any, action: any) => {
        return update(state, {
            NOTIFIER: {$set: action.data},
        });
    },
    [Types.CLEAR_STATE]: (state: any, action: any) => {
        return getInitial();
    },
    [Types.LOAD_BOWLINGCENTERS_SUCCESS]: (state: any, action: any) => {
        return update(state, {
            BOWLINGCENTERS: {
                $set: action.payload.data
            },
        });
    },
    [Types.LOAD_ELEMENTS_HISTORY_SUCCESS]: (state: any, action: any) => {
        return update(state, {
            ELEMENTS_HISTORY: {
                $set: action.payload
            },
        });
    },

    [Types.LOAD_BOWLING_CENTERS_HISTORY_SUCCESS]: (state: any, action: any) => {
        return update(state, {
            BOWLINGCENTERS_HISTORY: {
                $set: action.payload
            },
        });
    },
    [Types.SELECT_BOWLINGCENTER_SUCCESS]: (state: any, action: any) => {
        return update(state, {
            BOWLINGCENTER_SELECTED: {
                $set: action.payload
            }
        });
    },
    [Types.LOAD_MATCHES_SUCCESS]: (state: any, action: any) => {
        return update(state, {
            MATCHES: {
                $set: action.payload.map((m: any) => ({...m, days: moment.unix(m.expires).diff(moment.now(), 'days')}))
            },
        });
    },
    [Types.SELECT_MATCH_SUCCESS]: (state: any, action: any) => {
        return update(state, {
            MATCH_SELECTED: {
                $set: {players: action.payload}
            },
        });
    },
    [Types.LOAD_AVATAR_SUCCESS]: (state: any, action: any) => {
        return update(state, {
            AVATARS: {
                $set: {
                    ...state.AVATARS,
                    [action.payload.id]: {
                        avatar: action.payload.avatar
                    }
                }
            },
        });
    },
    [Types.LOAD_IMAGES_MATCHES_SUCCESS]: (state: any, action: any) => {
        return update(state, {
            IMAGES_MATCHES: {
                $push: [action.payload]
            },
        });
    },
    [Types.REFRESH_POSITIONS_PLAYERS_SUCCESS]: (state: any, action: any) => {
        return update(state, {
            POSITIONS_PLAYERS: {
                $set: action.payload
            },
        });
    },
};
const app = createReducer(getInitial(), handlers);

export default app;
