import React from 'react'
import { useTable } from 'react-table'
//import './styles.css';

function CustomTable({ columns, data, handleSelectMatches,onGoing }: any) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()} className='table'>
      <thead className='tableHeader'>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} style={{ position: "sticky", top: '0' }}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}
                className={`tableHeaderCell ${!onGoing?'':'tableHeaderCellFinish'}`}style={{ width: column.width }}>{column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className='tableBody'>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className='tableRowGroup' onClick={() => handleSelectMatches(row.original)}>
              {row.cells.map(cell =>
                <td {...cell.getCellProps()} className={`tableDataCell ${!onGoing?'':'tableDataCellFinish'}`}>
                 {cell.render('Cell')}
                </td>
              )}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
export default CustomTable