import React from 'react';
import PropTypes from 'prop-types';
import {Route, RouteProps} from 'react-router-dom';

const GuestRouteRoutes: any = ({Component = () => {} , props}: Props) => {
    return (
    <Route {...props} render={props => <Component {...props} /> }>
    </Route>
    )
};
interface Props extends RouteProps {
    Component: any;
    props: any;
}
GuestRouteRoutes.propTypes = {
    component: PropTypes.func,
};

export default GuestRouteRoutes;