import React from 'react';

const ContentApp = (props: any)=>{
    return (
        <div className={props.className}>
            {props.children}
        </div>
    )
};

export default ContentApp;
