import React from "react";
import Avatar from "../assets/imgs/anonymous_user.png";

const PlayerSlam = (props: any) => {
  const { player, expanded, header, handleOnClickPlayer } = props;
  const {
    country,
    firstName,
    lastName,
    pointsByMonth,
    total,
    position,
    thumbnail,
  } = player;

  return (
    <li
      className={`d-flex player-item-slam ${header && "player-header-slam"}`}
      style={{ minHeight: expanded && window.innerWidth < 931 ? "156px" : "" }}
      onClick={handleOnClickPlayer}
    >
      <div className={`player-position-slam d-flex `}>
        {header && window.innerWidth < 931
          ? position.substring(0, 3)
          : position}
      </div>
      <div
        className={`player-flag-slam d-flex ${
          window.innerWidth < 931 && "display-none"
        }`}
      >
        {country && !!country.trim() ? (
          <img
            src={`../assets/imgs/countryflags/${
              country && country.toLowerCase()
            }.imageset/${country && country.toLowerCase()}.png`}
            alt=""
            className="player-image-flag"
          />
        ) : (
          <div className="player-image-flag"></div>
        )}
      </div>
      <div className="d-flex info-avatar-slam">
        {!header ? (
          <img
            src={thumbnail ? `data:image/png;base64, ${thumbnail}` : Avatar}
            alt=""
            className="player-image"
          />
        ) : (
          <div className="player-image"></div>
        )}
        <div className="player-info-slam">
          <span style={{ marginRight: header ? 0 : "5px" }}>{firstName}</span>
          <span>{lastName}</span>
          {country && !!country.trim() && window.innerWidth < 931 && (
            <span className="container-image-flag">
              <img
                src={`../assets/imgs/countryflags/${
                  country && country.toLowerCase()
                }.imageset/${country && country.toLowerCase()}.png`}
                alt=""
                className="player-image-flag"
              />
            </span>
          )}
        </div>
      </div>
      <div
        className={`player-container-months d-flex ${
          !expanded && window.innerWidth < 931 ? "display-none" : ""
        }`}
      >
        {pointsByMonth &&
          pointsByMonth.map((g: any, i: any) => (
            <div className="d-flex flex-column player-month" key={i}>
              {(window.innerWidth < 931 || header) && (
                <div className="label-month">{g.month}</div>
              )}
              <div>{g.points}</div>
            </div>
          ))}
      </div>
      <div className={"player-scores-slam d-flex"}>
        {total}
        {/*<div  className={"player-scores-slam"}>
                    { currentCut && ( <div className={`player-score ${expanded && window.innerWidth < 631 && 'paddingExpanded'}`}>
                        <div>{`${(totalPoints)-(currentCut) > 0 ?'+':''}${(totalPoints)-(currentCut)||0}`}</div>
                      </div> )
                    }
                    <div className={`player-score ${expanded && window.innerWidth < 631 && 'paddingExpanded'}`}>
                        <div>{totalPoints}</div>
                    </div>
                </div>*/}
      </div>
    </li>
  );
};

export default PlayerSlam;
