import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { makeStyles} from '@material-ui/core/styles';
import MatchDetails from './MatchDetails';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      boxShadow:'none',
      background:'transparent',
      textAlign:'left',
      alignItems:'center'
      
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width:375,
      [theme.breakpoints.down("md")]: {
        minWidth:280,
      },
      [theme.breakpoints.down("sm")]: {
        width:'100%',
      },
    },
    content: {
      flex: '0 1 auto',
       paddingLeft:32,
       [theme.breakpoints.down("xl")]: {
        maxWidth:414
      },
       [theme.breakpoints.down("sm")]: {
        paddingTop:24
      },
    },
    cover: {
     height:191,
      
    },
    title:{
      color:'#FFE888',
      fontSize:'1.875em',
      fontFamily:'SF Pro Display Bold',
      marginBottom:24,
      [theme.breakpoints.down("md")]: {
        marginBottom:10,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize:23,
        letterSpacing:0.33
      },
    },
    subTitle:{
        color:'#DEDEDE',
        fontFamily:'SF Pro Text Regular',
        fontSize:18,
        [theme.breakpoints.down("sm")]: {
          fontSize:15,
          letterSpacing:-0.23
        },
    },
    links:{
      color:'#FFFFFF',
      textDecoration:'underline'
    },
    bowling:{
      color:'#FFFFFF',
      fontFamily:'SF Pro Text Regular',
      marginTop:"2em",
      fontSize:'1.3em'
  },
    controls:{
        minHeight:33,
        display: 'flex',
        alignItems: 'center',
        justifyContent:'center',
        background:'#000000',
        color:'#FFFFFF',
        fontSize:13 
    }
  }));

const CardMatch = (props: any, context: any) => {
  const classes = useStyles();
  const {id, games, players, title, description} = props.matchSelected || {};
  const mapIds:any = {
    "3960":5000,
    "4037":1000,
  }

  const getClickableLink = (link:any) => {
    return link.startsWith("http://") || link.startsWith("https://") ?
      link
      : `//${link}`;
  };

  const findLink = (text:any) => {
    const validUrl = (url: any) => {
       // eslint-disable-next-line no-useless-escape
      const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
      return regex.test(url);
    } 
    return text?.split(' ').map((val: string) => 
    validUrl(val)?
       // eslint-disable-next-line no-useless-escape
       (<><a className={classes.links} href={getClickableLink(val.replace(/(\,|\.)$/, ''))} target="_blank" rel="noreferrer noopener">{val}</a> </>)
    : `${val} `);
  };

    return (
        <div className='container-card'>
          <Card className={`${classes.root} root`}>
              <div className={classes.details}>
                  {props.image && <CardMedia className={classes.cover} image={`${props.image}`} title="Live from space album cover"/>}
                  <div className={classes.controls}>
                    <MatchDetails games={`${games||0} games`} 
                    time={`${props.time||0} d`} 
                    players={`${mapIds[`${id}`] || (players && players.length) || 0} players`}/>
                  </div>
              </div>
              <CardContent className={classes.content}>
                  <Typography className={`${classes.title} title`}>
                      {title}
                  </Typography>
                  <Typography className={classes.subTitle}>
                      {findLink(description)}
                  </Typography>
                  <Typography className={classes.bowling}>
                      {props.matchSelected && props.bcName}
                  </Typography>
              </CardContent>
          </Card>
        </div>
    )
}    

CardMatch.contextTypes = {
    t: PropTypes.func.isRequired,
};
    
export default CardMatch;

